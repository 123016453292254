<template>
    <div class="container mt-5">

        <div class="col-12 mt-3 animate__animated animate__backInRight animate__delay-1s">
            <img class="d-inline mx-3" src="../assets/services/servis-logo.webp" alt="logo"
                style="width: 100px; height: 100px;">
            <h2 class="d-inline mt-3">Servis Xidmətlərimiz aşağdakılardır:</h2>
            <ul>
                <li>Kondisionerlərin quraşdırılması, təmizlənməsi (yuyulması), Freon doldurulması, köhnəsinin yenisi
                    ilə dəyişdirilməsi, təmiri.</li>
                <li>Çiller-Fankoyl Sistemlərinin baxımı və təmiri</li>
                <li>VRF Sistemlərinin baxımı və təmiri</li>
                <li>Demontaj və montaj işləri (yəni bir kondisioneri bir yerdən söküb digər yerdə quraşdırmaq)</li>
                <li>Kondensatorun dəyişdirilməsi</li>
                <li>Dördyolun dəyişdirilməsi</li>
                <li>Kompressorun dəyişdirilməsi</li>
                <li>Platanın Təmiri</li>
                <li>Kombi materialları, quraşdırılması və təmiri və s.</li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AzfireService',

    data() {
        return {

        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style lang="scss" scoped>
li {
    font-size: 23px;
}
</style>