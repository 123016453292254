<template>
    <div class="container">
        <div class="row">
            <div class="col-6 animate__animated animate__backInLeft animate__delay-1s">
                <div class="card border-0" style="width: 600px;">
                    <div class="card-body">
                        <h4 class="card-title text-center ">Havalandırma Sistemləri</h4>
                        <p class="card-text">Havalandırma sistemləri bütün inzibati və yaşayış binalarında quraşdırılan
                            ən
                            mühüm
                            ünsürlərdən biridir. Havalandırma sistemlərinin texniki xüsusiyyətləri beynəlxalq
                            standartların
                            normalarına
                            cavab verməlidir.

                            Havalandırma sistemlərinin mühüm əhəmiyyəti olmasının səbəbi onunla izah edilir ki, bu
                            sistemlərin
                            yoxluğu
                            nəticəsində qapalı yerlərdə karbon qazının və digər zərərli qazların artması müşahidə
                            olunur. Bu
                            isə
                            insanların özünü pis hiss etməsinə, baş ağrılarına, yorğunluğa, əmək məhsuldarlığının
                            azalmasına
                            və
                            digər
                            neqativ hallara səbəb olur.

                            Sadalanan bu halların qarşısını almaq üçün isə havalandırma sistemlərinin tətbiq olunması
                            zəruridir.
                        </p>
                    </div>
                    <img src="../assets/services/havalandirma.jpg" class="card-img-top image-card mt-4"
                        alt="havalandirma sistemleri">

                </div>
            </div>
            <div class="col-6 animate__animated animate__backInRight animate__delay-1s">
                <div class="card border-0" style="width: 600px; ">
                    <div class="card-body">
                        <h4 class="card-title text-center">İsitmə Sistemləri</h4>
                        <p class="card-text">İsitmə sistemləri istifadə məkanlarının istənilən istilikdə tutulması üçün
                            daxili mühitdən xarici mühitə (ətrafa) istilik itkisinin qarşılanması prinsipi ilə çalışan
                            sistemdir.
                        </p>
                        <p class="lh-sm ps-2">Qazanxana sistemi</p>
                        <p class="lh-sm ps-2"> Radiator sistemi</p>
                        <p class="ps-2"> Yerdən isitmə sistemi</p>
                        <p class="ps-2">İstilik nasosu</p>
                    </div>
                    <img src="../assets/services/istilik.jpg" class="card-img-top image-card" alt="istilik sistemleri">

                </div>
            </div>


            <div class="col-12 justify-content-center d-flex mt-3 ">
                <div class="card border-0" style="width: 1000px; ">
                    <div class="card-body">
                        <h4 class="card-title text-center">Soyutma Sistemləri</h4>
                        <p class="card-text">Müştərilərin seçimindən, həmçinin soyutma sistemi qurulacaq binanın
                            təyinatından asılı olaraq aşağdakı soyutma sistemlərinin layihələndirilməsi,
                            avadanlıqlarının satışını və quraşdırılması işlərini həyata keçirir:
                        </p>
                        <p class="lh-sm ps-2">VRV sistemləri</p>
                        <p class="lh-sm ps-2">Multi split kondisioner sistemləri</p>
                        <p class="lh-sm ps-2">Çiller və fankoil soyutma sistemlıri</p>
                        <p class="lh-sm ps-2">Server otaqlarının həssas tipli soyutma sistemləri</p>

                    </div>
                    <img src="../assets/services/soyutma.jpg" class="card-img-top image-card" alt="soyutma sistemleri">

                </div>
            </div>

            <div class="col-12 justify-content-center d-flex mt-3 ">
                <div class="card border-0" style="width: 1000px; ">
                    <div class="card-body">
                        <h4 class="card-title text-center">Xüsusi avtomatik yanğın söndürmə sistemləri</h4>
                        <p class="card-text">Yanğından Mühafizə Sistemlərinin - Yanğın Alarm, Yanğın-mühafizə
                            siqnalizasiyası, avtomatik sulu söndürmə, qazlı söndürmə (FM200, Karbondioksid,
                            Köpüklü),mətbəx davlumbaz söndürmə və s. sistemlərninin, Fövqalədə Hallar Nazirliyinin
                            verdiyi xüsusi lisenziyaya əsasən standartlara uyğun layihələndirilməsini, quraşdırılmasını
                            ,texniki baxışını və təmirini həyata keçirir.
                        </p>


                    </div>
                    <img src="../assets/services/sondurme.jpg" class="card-img-top image-card"
                        alt="sondurme sistemleri">

                </div>
            </div>

            <div class="col-12 justify-content-center d-flex mt-3 ">
                <div class="card border-0" style="width: 1000px; ">
                    <div class="card-body">
                        <h4 class="card-title text-center">Dozimetrik ölçü cihazları</h4>
                        <p class="card-text">Dozimetrik cihazlar – onların təyinatına, vericisinin tipinə, ölçülən
                            şüalanmanın növünə və cihazın sxeminin çevirdiyi elektrik siqnallarının xarakterinə görə
                            təsnif etmək olar. Dozimetriya vasitəsilə aşağıdakı işlər yerinə yetirilir:
                        </p>
                        <ul>
                            <li>Əhalinin həyat fəaliyyətinin və zədələnmə ocaqlarında X və DTİ təhlükəsizliyini təmin
                                etmək üçün şüalanmaların aşkar edilməsi və ölçülməsi; </li>
                            <li>Dezaktivasiya və sanitariya təmizlənməsi keçirilməsi zərurətini, bunların keyfiyyətini
                                müəyyən etmək, habelə zəhərlənmiş ərzaq məhsullarından istifadə etməyin mümkünlüyünü,
                                normalarını təyin etmək üçün müxtəlif obyektlərin zəhərlənmə dərəcələrinin ölçülməsi;
                            </li>
                            <li>Radiasiya baxımından əhalinin və ayrı-ayrı adamların iş qabiliyyətini təyin etmək
                                məqsədilə şüalanma dozalarının ölçülməsi;</li>
                            <li>Ərzaq məhsullarının, suyun, alafın radioaktiv maddələrlə zəhərlənmə dərəcələrinin
                                laboratoriyalarda ölçülməsi;</li>
                        </ul>


                    </div>
                    <img src="../assets/services/dozimetr.jpg" class="card-img-top image-card" alt="dozimetr cihazi">

                </div>
            </div>
            <div class="col-12 mt-3 ">
                <img class="d-inline mx-3" src="../assets/services/servis-logo.webp" alt="logo"
                    style="width: 100px; height: 100px;">
                <h4 class="d-inline mt-3">Servis Xidmətlərimiz aşağdakılardır:</h4>
                <ul>
                    <li>Kondisionerlərin quraşdırılması, təmizlənməsi (yuyulması), Freon doldurulması, köhnəsinin yenisi
                        ilə dəyişdirilməsi, təmiri.</li>
                    <li>Çiller-Fankoyl Sistemlərinin baxımı və təmiri</li>
                    <li>VRF Sistemlərinin baxımı və təmiri</li>
                    <li>Demontaj və montaj işləri (yəni bir kondisioneri bir yerdən söküb digər yerdə quraşdırmaq)</li>
                    <li>Kondensatorun dəyişdirilməsi</li>
                    <li>Dördyolun dəyişdirilməsi</li>
                    <li>Kompressorun dəyişdirilməsi</li>
                    <li>Platanın Təmiri</li>
                    <li>Kombi materialları, quraşdırılması və təmiri və s.</li>
                </ul>
            </div>

        </div>
    </div>
</template>

<script>
import 'animate.css';
export default {
    name: 'AzfireServices',

    data() {
        return {

        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style lang="scss" scoped>
.image-card {
    height: 430px;
}

.card-text {
    text-indent: 50px;
}
</style>