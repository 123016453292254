<template>
    <div class="container my-4">
        <h1 class="text-center">Haqqımızda</h1>
        <h3>“Laxas” Məhdud Məsuliyyətli Müəssisəsi </h3>
        <div>
            <p class="fw-bold">Mərkəzləşdirilmış isitmə-soyutma, havalandırma (HVAC) sistemləri:</p>
            <p> - chiller-fancoil (McQuay, Clint)</p>
            <p> - VRF/VRV (Pioneer, McQuay, Fujitsu),</p>
            <p> - multizonal Pioneer, McQuay, Fujitsu) - split kondisionerlər,</p>
            <p> - mərkəzi kondisionerlər (AHU),</p>
            <p> - nəmləndirici və quruducular (humidifiers, dehumidifiers) Danterm.</p>
        </div>
        <div>
            <p class="fw-bold"> Xüsusi avtomatik yanğın söndürmə sistemləri: </p>
            <p>- yanğın həyəcan xəbər vermə, təsirsiz qaz (NN100), su tozu (water mist),
                su topu və digər sistemlər(fire
                trace) vasitəsi ilə avtomatik yangin söndürmə
                avadanlıqlarının və sistemlərinin təchizi, quraşdırılması, sevis xidməti.</p>
        </div>
        <div>
            <p class="fw-bold"> Dozimetrik ölçü cihazları, qoruyucu geyim:</p>
            <p>- İndividual və stasionar radiometr-dozimetr, spektrometrlər</p>
            <p>- Şüalanmadan qoruyucu geyimlər</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AzfireAbout',

    data() {
        return {

        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style lang="scss" scoped></style>