<template>
    <div class="container my-5">
        <div class="row">
            <h2 class="text-center my-3">Brendlər</h2>

            <div class="col-6 col-md-2">
                <a href="https://www.lavastica.com/home.aspx" target="_blank">
                    <img class="brend-link" src="../assets/images/brand/Lavastica.png" alt="lavastica"
                        style="padding-top: 70px; width: 170px;">
                </a>
            </div>
            <div class="col-6 col-md-2">
                <a href="https://www.akronbrass.com/" target="_blank">
                    <img class="brend-link" src="../assets/images/brand/akron.gif" alt="akronbrass"
                        style="width: 170px; padding-top: 50px;">
                </a>
            </div>
            <div class="col-6 col-md-2">
                <a href="https://www.komtes.com/" target="_blank">
                    <img class="brend-link" src="../assets/images/brand/komtes.png" alt="komtes"
                        style="width: 170px; padding-top: 50px;">
                </a>
            </div>
            <div class="col-6 col-md-2">
                <a href="https://www.2mkablo.com/" target="_blank">
                    <img class="brend-link" src="../assets/images/brand/kablo.png" alt="2mkablo"
                        style="width: 170px; padding-top: 66px;">
                </a>
            </div>
            <div class="col-6 col-md-2">
                <a href="https://www.firemiks.com/" target="_blank">
                    <img class="brend-link" src="../assets/images/brand/firemiks.png" alt="firemiks"
                        style="width: 170px; padding-top: 66px;">
                </a>
            </div>
            <div class="col-6 col-md-2">
                <a href="https://www.daikin.com/" target="_blank">
                    <img class="brend-link" src="../assets/images/brand/daikin.png" alt="daikin"
                        style="width: 170px; padding-top: 66px;">
                </a>
            </div>
            <div class="col-6 col-md-2">
                <a href="https://uel.ru/" target="_blank">
                    <img class="brend-link" src="../assets/images/brand/uer.png" alt="uel"
                        style="width: 170px; padding-top: 66px;">
                </a>
            </div>
            <div class="col-6 col-md-2">
                <a href="https://venco.com.tr/" target="_blank">
                    <img class="brend-link" src="../assets/images/brand/venco.png" alt="venco"
                        style="width: 200px; padding-top: 66px;">
                </a>
            </div>
            <div class="col-6 col-md-2">
                <a href="https://clint.it/en/" target="_blank">
                    <img class="brend-link" src="../assets/images/brand/clint.svg" alt="clint"
                        style="width: 130px; padding-top: 50px;">
                </a>
            </div>
            <!-- <div class="col-6 col-md-2">
                <a href="https://atomtex.com/" class="text-decoration-none text-dark" target="_blank">
                    <h4 class="">Atomtex</h4>
                    <img src="../assets/images/brand/atomtex.avif" alt="atomtex" width="100px" height="70px">
                </a>
            </div> -->
            <div class="col-6 col-md-2">
                <a href="https://cla-val-europe.com/en/" target="_blank">
                    <img class="brend-link" src="../assets/images/brand/cla-val.png" alt="cla-val-europe"
                        style="width: 130px; padding-top: 40px;">
                </a>
            </div>
            <div class="col-6 col-md-2">
                <a href="https://www.duyar.com/" target="_blank">
                    <img class="brend-link" src="../assets/images/brand/duyar.png" alt="duyar"
                        style="width: 130px; padding-top: 40px;">
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AzfireBrand',

    data() {
        return {

        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style lang="scss" scoped>
h2 {
    font-size: 50px;
}

@media screen and (max-width:576px) {
    .brend-link {
        width: 130px !important;
        padding-left: 20px;
        padding-top: 0 !important;
        margin-bottom: 20px;
    }

    h2 {
        font-size: 30px;
    }
}
</style>